import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IConnectTokenParams {
  product: 'transactions',
  management_group_id?: TID
}

interface IConnectQuilttGenerateTokenResponse {
  quiltt: {
    url: string
  }
}

interface IConnectQuilttRetrieveTokenResponse {
  quiltt: {
    connectionId: string,
    session: string,
    instituionId: string
  }
}

interface IRetrieveAggregatorLinkTokenParams {
  financialInstitutionConnectionId?: TID,
}

interface IConnectPlaidTokenResponse {
  plaid: {
    linkToken: string
  }
}

type IConnectTokenResponse = IConnectQuilttGenerateTokenResponse & IConnectPlaidTokenResponse

type IReconnectRetrieveLinkTokenResponse = IConnectQuilttRetrieveTokenResponse & IConnectPlaidTokenResponse

const generateToken = (
  params: IConnectTokenParams,
): Promise<IConnectTokenResponse> => {
  return apiPost('/banking/api/v1/banking_management_groups/generate_link_token', underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IConnectTokenResponse);
};

const retrieveLinkToken = (
  params: IRetrieveAggregatorLinkTokenParams
): Promise<IReconnectRetrieveLinkTokenResponse> => {
  return apiGet(`/banking/api/v1/financial_institution_connections/${params.financialInstitutionConnectionId}/retrieve_link_token`)
    .then((data) => camelizeKeys(data) as IReconnectRetrieveLinkTokenResponse)
}

export {
  IConnectTokenParams,
  IConnectTokenResponse,
  IConnectQuilttGenerateTokenResponse,
  IConnectPlaidTokenResponse,
  IRetrieveAggregatorLinkTokenParams,
  IReconnectRetrieveLinkTokenResponse,
  generateToken,
  retrieveLinkToken
};
