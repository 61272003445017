/* eslint-disable react/jsx-wrap-multilines */

import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IReport } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IBudgetValue, IItemValue, IReportData } from '@src/types/report_service/report_data';
import { IReportItem } from '@src/types/report_service/report_item';
import { API_DATE_FORMAT, formatDate } from '@src/utils/date_helpers';
import { uiStyleClassNames } from '@src/utils/ui_style_helpers';

import { pickMultiMonths, reportDataPeriodType } from '@src/components/business_reports/atoms';
import { AppLink } from '@src/components/ui_v2/buttons';
import Table from '@src/components/ui_v2/table';
import { CaretIcon } from '@src/components/utils/fa_icons';

import { isBasicReport, isBudgetColumn, isForecastColumn, itemValueFormatter } from '../../hooks';

interface IParentTotalItem {
  itemId: string;
  name: string;
  childItemId: string;
  childItemName: string;
}

interface ITableRowProps {
  report: IReport;
  item: IReportItem;
  reportData: IReportData[];
  depth?: number;
  multiMonthColumns: IReportColumn[];
  ptdColumns: IReportColumn[];
  ytdColumns: IReportColumn[];
  hideZeroRows: boolean;
  budgetsComparison: string;
  forecastComparison: string;
  parentTotalData: IParentTotalItem[];
  searchValue: string;
}

const TableRow = ({
  report,
  item,
  reportData,
  depth = 0,
  multiMonthColumns,
  ptdColumns,
  ytdColumns,
  hideZeroRows,
  budgetsComparison,
  forecastComparison,
  parentTotalData,
  searchValue,
}: ITableRowProps): JSX.Element => {
  const business = useBusinessContext();
  const [showChildRows, setShowChildRows] = useState(true);
  const showMultiMonths = useRecoilValue(pickMultiMonths);
  const periodType = useRecoilValue(reportDataPeriodType);
  const isReportTypeV2 = report.version === 2;
  const isLaborReportV2 = isReportTypeV2 && report.templateId === 'labor_report';
  const showMultiMonthColumns = isLaborReportV2 && periodType === 'daily'; // labor report v2 daily need to show all multiMonthColumns

  const data = useMemo(() => {
    if (report.showOnlyTotalColumn) return reportData[0] ? [reportData[0]] : [];
    return reportData;
  }, [report.showOnlyTotalColumn, reportData]);

  const childItems = useMemo(
    () => {
      const itms = item.childItems.filter((it) => it.show && it.search && data);
      return itms.sort((a, b) => a.order - b.order || a.name.localeCompare(b.name));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item.childItems, data, searchValue],
  );

  const datePeriod = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = formatDate(params.get('from'), API_DATE_FORMAT);
    const endDate = formatDate(params.get('to'), API_DATE_FORMAT);

    return {
      startDate,
      endDate
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isParentItem = useMemo(() => item.childItems.length !== 0, [item.childItems.length]);

  const currentDepth = useMemo(() => depth + item.depthDiff, [depth, item.depthDiff]);

  const columns = useMemo(
    () => (showMultiMonths ? multiMonthColumns : [...ptdColumns, ...ytdColumns]),
    [multiMonthColumns, ptdColumns, ytdColumns, showMultiMonths],
  );

  const ptdColumn = useMemo(
    () => ptdColumns.find((c: IReportColumn) => c.type === 'actual' && c.year === 'current'),
    [ptdColumns],
  );

  const colSpanBottom = useMemo(() => {
    return ytdColumns.length !== 0
      && !showMultiMonths
      ? ptdColumns.length - ytdColumns.length + 1
      : 1;
  }, [ptdColumns, ytdColumns, showMultiMonths]);

  const lineItemLink = useMemo(() => {
    let allowClickable = false;
    if (isReportTypeV2) {
      const columnValues = item.columnValues;
      const columnID = window.Docyt.Common.Constants;
      const isPTDQuickBookLedger = columnValues.some(
        (column) => column.columnId === columnID.V2_REPORT_COLUMN_ID.PTD_ACTUAL
          && column.typeConfig.name === columnID.CUSTOM_REPORT_TYPE.QUICKBOOKS_LEDGER,
      );
      const isYTDQuickBookLedger = columnValues.some(
        (column) => column.columnId === columnID.V2_REPORT_COLUMN_ID.YTD_ACTUAL
          && column.typeConfig.name === columnID.CUSTOM_REPORT_TYPE.QUICKBOOKS_LEDGER,
      );
      allowClickable = isPTDQuickBookLedger && isYTDQuickBookLedger;
    } else {
      allowClickable =
        item.type === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.QUICKBOOKS_LEDGER;
    }

    if (
      !isBasicReport(report)
      && periodType !== 'daily'
      && allowClickable
    ) {
      return `/businesses/${business.id}/reports/${report.slug}/items/${item.identifier}/item_account_values?from=${datePeriod.startDate}&to=${datePeriod.endDate}`;
    }
    return false;
  }, [
    isReportTypeV2,
    report,
    periodType,
    item.columnValues,
    item.type,
    item.identifier,
    data,
    business.id,
  ]);

  const renderTotalStyle = useCallback(() => {
    if (isReportTypeV2) {
      return item.aggregation === 'sum';
    }

    return item.totals;
  }, [isReportTypeV2, item]);

  const lineItemCellClasses = uiStyleClassNames(
    `${isParentItem ? `p-l-${18 + 12 * currentDepth}` : `p-l-${38 + 12 * currentDepth}`} line-item-cell`,
    {
      fontVariant: renderTotalStyle() ? 'bold' : undefined,
    },
  );

  const classesActiveColumns = useCallback((name: string) => {
    return classNames('value-cell', {
      background: name === 'PTD $' || name === 'YTD $',
    });
  }, []);

  const getValue = useCallback(
    (repData: IReportData, col?: IReportColumn, filterItemId?: string) => {
      let column: IReportColumn | undefined;
      let itemValue: IItemValue | undefined;

      const newItemId = filterItemId || item.id;

      if (col) {
        itemValue = repData?.itemValues.find(
          (iv: IItemValue) => iv?.itemId === newItemId && iv?.columnId === col?.id,
        );
        column = col;
      } else if (columns?.length === 0) {
        itemValue = repData?.itemValues?.find(
          (iv: IItemValue) => iv?.itemId === newItemId && iv?.columnId === ptdColumn?.id,
        );
      } else {
        for (let i = 0; i < repData?.itemValues?.length; i += 1) {
          const iv = repData?.itemValues?.[i];

          for (let j = 0; j < columns?.length; j += 1) {
            const c = columns?.[j];
            if (iv?.itemId === newItemId && iv?.columnId === c?.id) {
              column = c;
              break;
            }
          }

          if (column) {
            itemValue = iv;
            break;
          }
        }

        itemValue = repData?.itemValues?.find((iv: IItemValue) => {
          column = columns?.find(
            (c: IReportColumn) => iv?.itemId === newItemId && iv?.columnId === c?.id,
          );

          if (column) {
            return iv;
          }

          return undefined;
        });
      }

      if (!itemValue) {
        return {
          value: '-',
          valueLink: false,
          totals: isReportTypeV2 ? item.aggregation === 'sum' : item.totals,
        };
      }

      if (column && (isBudgetColumn(column as IReportColumn) || isForecastColumn(column as IReportColumn))) {
        let budgetValue: IBudgetValue | undefined;

        if (isBudgetColumn(column as IReportColumn)) {
          budgetValue = itemValue?.businessBudgetValues?.find(
            (v: IBudgetValue) => v.reportComparerId === budgetsComparison,
          );
        }

        if (isForecastColumn(column as IReportColumn)) {
          budgetValue = itemValue?.businessForecastValues?.find(
            (v: IBudgetValue) => v.reportComparerId === forecastComparison,
          );
        }

        if (!budgetValue) {
          return { value: '-', valueLink: false, totals: item.totals };
        }

        return {
          value: itemValueFormatter(
            columns,
            item,
            itemValue?.columnId,
            itemValue?.itemId,
            itemValue?.columnType,
            budgetValue?.value,
          ),
          valueLink: false,
          totals: isReportTypeV2 ? item.aggregation === 'sum' : item.totals,
        };
      }

      if (col) {
        if (isBasicReport(report)
        && moment(repData?.startDate).isSame(repData?.endDate, 'month')
        && item?.type === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.QUICKBOOKS_LEDGER
        && column?.type === window.Docyt.Common.Constants.ADVANCED_REPORT_COLUMN_TYPE.ACTUAL) {
          return {
            value: itemValueFormatter(
              columns,
              item,
              itemValue?.columnId,
              itemValue?.itemId,
              itemValue?.columnType,
              itemValue?.value,
            ),
            valueLink: `/businesses/${business?.id}/reports/${report?.slug}/report_datas/${repData?.id}/line_item_details?item_id=${item?.id}&column_id=${column?.id}`,
            totals: isReportTypeV2 ? item.aggregation === 'sum' : item.totals,
          };
        }
      }

      if (!col) {
        if (isBasicReport(report)
          && moment(repData?.startDate).isSame(repData?.endDate, 'month')
          && item?.type === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.QUICKBOOKS_LEDGER) {
          return {
            value: itemValueFormatter(
              columns,
              item,
              itemValue?.columnId,
              itemValue?.itemId,
              itemValue?.columnType,
              itemValue?.value,
            ),
            valueLink: `/businesses/${business?.id}/reports/${report?.slug}/report_datas/${repData?.id}/line_item_details?item_id=${item?.id}&column_id=${ptdColumn?.id}`,
            totals: isReportTypeV2 ? item.aggregation === 'sum' : item.totals,
          };
        }
      }

      return {
        value: itemValueFormatter(
          columns,
          item,
          itemValue?.columnId,
          itemValue?.itemId,
          itemValue?.columnType,
          itemValue?.value,
        ),
        valueLink: false,
        totals: isReportTypeV2 ? item.aggregation === 'sum' : item.totals,
      };
    },
    [
      business.id,
      report,
      budgetsComparison,
      columns,
      item,
      forecastComparison,
      ptdColumn?.id,
      isReportTypeV2
    ],
  );

  const getTotalRowItemId = () => {
    const totalItemRow = parentTotalData.filter(
      (val: IParentTotalItem) => val.itemId === item.id,
    )[0];
    return totalItemRow?.childItemId;
  };

  const renderRowCell = useCallback((cellItem: {
      value: string;
      valueLink: boolean;
      totals: boolean;
    } | {
      value: string;
      valueLink: string;
      totals: boolean;
    }) => {
    if (cellItem.value.indexOf('$') !== -1) {
      return (
        <>
          <div>$</div>
          <div>{ cellItem.valueLink ? <AppLink href={ cellItem.valueLink as string }>{ cellItem.value.replace('$', '') }</AppLink> : cellItem.value.replace('$', '') }</div>
        </>
      );
    }

    return (
      <>
        <div />
        <div>
          {
            cellItem.valueLink
              ? <AppLink href={ cellItem.valueLink as string }>{ cellItem.value }</AppLink>
              : cellItem.value
          }
        </div>
      </>
    );
  }, []);

  const renderTableCell = (key: number, repData: IReportData, col?: IReportColumn) => {
    let filterItemId;

    if (isParentItem && !showChildRows) {
      filterItemId = getTotalRowItemId();
    }

    const cellItem = getValue(repData, col, filterItemId);

    return (
      <Table.TextCell
        hideValueTooltip
        className={ classesActiveColumns(col?.name as string) }
        { ...(key === (columns?.length || 0) - 1 ? { colSpan: colSpanBottom } : {}) }
      >
        <div { ...(cellItem.totals ? { style: { fontWeight: 'bold' } } : {}) }>{ renderRowCell(cellItem) }</div>
      </Table.TextCell>
    );
  };

  const handleClick = useCallback(() => {
    if (isParentItem) {
      setShowChildRows(!showChildRows);
    }
  }, [isParentItem, showChildRows, setShowChildRows]);

  return (
    <>
      <Table.Row isClickable className={ `table-row ${item.totals ? 'border-top' : ''}` } onClick={ handleClick }>
        <Table.TextCell className={ lineItemCellClasses } tooltip={ item.name }>
          {isParentItem && <CaretIcon className="m-r-10" fontSize={ 14 } variant={ showChildRows ? 'up' : 'down' } />}

          {lineItemLink ? (
            <AppLink newWindow href={ lineItemLink }>
              { item.name }
            </AppLink>
          ) : (
            item.name
          )}
        </Table.TextCell>

        {
          !showMultiMonthColumns
          && !showMultiMonths
          && data?.length === 1
          && columns.map((column: IReportColumn, index: number) => (
            <React.Fragment key={ column?.id }>
              { renderTableCell(index, data?.[0], column) }
            </React.Fragment>
          ))
        }

        {!showMultiMonthColumns
          && !showMultiMonths
          && data?.length > 1
          && data?.map((rep: IReportData, index: number) => (
          <React.Fragment key={ rep?.id }>{ renderTableCell(index, rep) }</React.Fragment>
        ))}

        {!showMultiMonths && columns.length === 0 && (
          <Table.TextCell hideValueTooltip>
            <div style={ {textAlign: 'right'} }>-</div>
          </Table.TextCell>
        )}

        {showMultiMonthColumns && multiMonthColumns?.length !== 0 && data?.map((rep: IReportData) => (
          multiMonthColumns?.map((column: IReportColumn, index: number) => (
            <React.Fragment key={ `${rep?.id}-${rep?.startDate}-${rep?.endDate}-${column?.id}` }>{ renderTableCell(index, rep, column)}</React.Fragment>
          ))
        ))}

        {showMultiMonths && multiMonthColumns?.length !== 0 && data?.map((rep: IReportData) => (
          columns?.map((column: IReportColumn, index: number) => (
            <React.Fragment key={ `${rep?.id}-${column?.id}` }>{ renderTableCell(index, rep, column)}</React.Fragment>
          ))
        ))}

        {
          showMultiMonths
          && multiMonthColumns?.length === 0
          && data?.map((rep: IReportData, index: number) => (
            <React.Fragment key={ rep?.id }>{ renderTableCell(index, rep) }</React.Fragment>
          ))
        }
      </Table.Row>

      { showChildRows && childItems.map((it) => (
        <TableRow
          key={ it.identifier }
          budgetsComparison={ budgetsComparison }
          depth={ currentDepth + 1 }
          forecastComparison={ forecastComparison }
          hideZeroRows={ hideZeroRows }
          item={ it }
          multiMonthColumns={ multiMonthColumns }
          parentTotalData={ parentTotalData }
          ptdColumns={ ptdColumns }
          report={ report }
          reportData={ reportData }
          searchValue={ searchValue }
          ytdColumns={ ytdColumns }
        />
      ))}
    </>
  );
};

export default TableRow;
