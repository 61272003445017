import React, { useCallback } from 'react';

import { ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn } from '@src/types/transaction_service_documents';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import BulkActions from '@src/components/reconciliation_center/all_transactions/bulk_actions/bulk_actions';
import { ITSDCollection } from '@src/components/reconciliation_center/all_transactions/hooks';
import CollectionTable from '@src/components/ui_v2/collection_table';

import CategorizedByIcon from '../../action_icons/categorized_by_icon';
import DocumentIcon from '../../action_icons/document_icon';
import FlaggedStateIcon from '../../action_icons/flagged_state_icon';
import TransactionType from '../../action_icons/transaction_type';
import AllTransactionsFilter from '../filter';
import Actions from './actions';
import { useBusinessContext } from '@src/hooks/contexts/business_context';

const accountName = (document: ITransactionServiceDocument) => {
  if (document.employeeCardName && document.employeeCardName !== document.paymentAccountName) {
    return [document.paymentAccountName, document.employeeCardName].join(' / ');
  }

  return document.paymentAccountName;
};

const amount = (document: ITransactionServiceDocument) => {
  const isCreditCard = document.reconciliationPaymentAccount.accountType
    === window.Docyt.Common.Constants.ACCOUNT_TYPES.CREDIT_CARD_ACCOUNT_TYPE;
  return isCreditCard ? -Number(document.amount) : (document.amount);
};

const documentIcon = (document: ITransactionServiceDocument) => {
  return <DocumentIcon document={ document } />;
};

const flaggedStateIcon = (document: ITransactionServiceDocument) => {
  return <FlaggedStateIcon document={ document } />;
};

const categorizedIcon = (document: ITransactionServiceDocument) => {
  return <CategorizedByIcon document={ document } />;
};

const transactionQBOStatusIcon = (document: ITransactionServiceDocument) => {
  return <QBOStatusIcon showIndividualSyncStatus fontSize={ 18 } model={ document } />;
};

const transactionType = (document: ITransactionServiceDocument) => {
  return (
    <TransactionType document={ document } />
  );
};

const actions = (document: ITransactionServiceDocument) => {
  return <Actions document={ document } />;
};

const AllTransactionsTable = ({ collection }: { collection: ITSDCollection }) => {
  const {
    query: infiniteQuery,
    records,
    sorting,
    selectedRecords,
    markAll,
    isAllSelected,
  } = collection;

  const business = useBusinessContext();

  const handleIsRowSelectable = useCallback((document: ITransactionServiceDocument) => {
    return document.transactionDate >= business.reconciliationStartDate
  }, []);

  const bulkActions = (
    <BulkActions
      isAllSelected={ isAllSelected }
      markAll={ markAll }
      selectedRecords={ selectedRecords.filter((record) => record.transactionDate >= business.reconciliationStartDate ) }
    />
  );

  return (
    <CollectionTable<ITransactionServiceDocument, TTransactionServiceDocumentsSortColumn>
      isRegionScroll
      showSelect
      filter={ (
        <AllTransactionsFilter bulkActions={ bulkActions } />
      ) }
      height="750px"
      isRowSelectable={ handleIsRowSelectable }
      query={ infiniteQuery }
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.DateColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="transaction_date"
        sortColumn="transaction_date"
        title="Date"
        value={ (document) => document.transactionDate }
      />
      <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="description"
        sortColumn="description"
        title="Description"
        value={ (document) => document.description }
        width="30%"
      />
      <CollectionTable.TextColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="payment_account"
        sortColumn="payment_account"
        title="Banking Account"
        value={ accountName }
        width="20%"
      />
      <CollectionTable.LabelColumn<
        ITransactionServiceDocument,
        TTransactionServiceDocumentsSortColumn
      >
        name="transaction_type"
        sortColumn="transaction_type"
        title="Type"
        value={ transactionType }
        variant={ (document) => document.transactionType }
        width="110px"
      />
      <CollectionTable.AmountColumn<
          ITransactionServiceDocument,
          TTransactionServiceDocumentsSortColumn
        >
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ amount }
        width="120px"
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="flagged_state"
        title="Flagged State"
        value={ flaggedStateIcon }
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="categorized_by"
        title="Categorized By"
        value={ categorizedIcon }
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="qbo_status"
        title="QBO Status"
        value={ transactionQBOStatusIcon }
      />
      <CollectionTable.IconColumn<ITransactionServiceDocument>
        name="document_type"
        title="Document Type"
        value={ documentIcon }
      />
      <CollectionTable.ActionsColumn<ITransactionServiceDocument>
        name="actions"
        title="Actions"
        value={ actions }
      />
    </CollectionTable>
  );
};

export default React.memo(AllTransactionsTable);
