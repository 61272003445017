import { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { useGenerateLinkToken, useAggregatorRetrieveLinkToken } from '@src/hooks/queries/generate_aggregator_token';
import { useGeneratePlaidItemLinkToken, useRetrievePlaidItemLinkToken } from '@src/hooks/queries/plaid_items';
import usePlaidService from '@src/hooks/usePlaid';
import useQuiltt from '@src/hooks/useQuiltt';
import { IPlaidMetadata, IQuilltMeta } from '@src/types/financial_institution_connection';
import { showLinkPlaidWindow } from '@src/utils/plaid';

import { IConnectNewFinancialInstitutionData } from './connect_financial_institution/schema';

// this is a hard code flag for this specific component which will decide whether plaid should open in same window or it should open in separate windoe
const OPEN_PLAID_IN_SAME_WINDOW = false;

type TAggregatorsType = 'plaid' | 'quiltt'

interface IUseConnectPlaidAccountParams {
  onAggregatorConnected: (data: IConnectNewFinancialInstitutionData) => void,
}

const useConnectAggregatorAccount = ({
  onAggregatorConnected,
}: IUseConnectPlaidAccountParams) => {
  const connectAggregatorToken = useGenerateLinkToken();
  const reconnectAggregatorToken = useAggregatorRetrieveLinkToken();

  const generateLink = useGeneratePlaidItemLinkToken();
  const retrieveLink = useRetrievePlaidItemLinkToken();

  const [accountData, setAccountData] = useState<
    IConnectNewFinancialInstitutionData | null>(null);

  const handleAggregatorAccountConnected = useCallback((
    metadata: IPlaidMetadata | IQuilltMeta,
  ) => {
    if (!accountData) return;

    // prepare the request data based on aggregator
    const aggregatorMetadata = (() => {
      if (metadata.type === 'quiltt') {
        return { quilttConnectionId: metadata.connectionId, quilttConnectorId: metadata.connectorId, quilttProfileId: metadata.profileId };
      }
      return { plaidPublicToken: metadata.publicToken, plaidAccountId: metadata.account_id };
    })();

    onAggregatorConnected({
      ...accountData,
      ...aggregatorMetadata,
    });

    setAccountData(null);
  }, [accountData, onAggregatorConnected]);

  const { open, ready: isPlaidReady, setLinkToken } = usePlaidService({
    // eslint-disable-next-line camelcase
    handleOnSuccess: (public_token, metadata) => {
      // eslint-disable-next-line camelcase
      const data = { ...metadata, publicToken: public_token };
      handleAggregatorAccountConnected(data);
    },
    handleOnExit: (error) => {
      if (error) {
        toastr.error(error, 'Error');
      }
    },
  });

  /**
   * This function used to load the Plaid UI also it will decide whether UI should load on current window or new window
   * @param linkToken palid link token
   */
  const loadPliadUI = useCallback((linkToken: string) => {
    // if this condition false plaid will be open in a saperate window
    if (OPEN_PLAID_IN_SAME_WINDOW) {
      // once linktoken set to the state it will trigger the useEffect and load the plaid UI in same window
      setLinkToken(linkToken);
    } else {
      showLinkPlaidWindow(linkToken);
    }
  }, [setLinkToken]);

  const { open: openQuiltt, setReConnectInstitution, importQuilttSession } = useQuiltt({
    handleOnSuccessCallback: (metadata) => {
      handleAggregatorAccountConnected(metadata);
    },
  });

  // Handle effect of updating the token for Plaid into state and open the Plaid window when it's ready
  useEffect(() => {
    if (isPlaidReady) {
      open();
    }
  }, [isPlaidReady, open]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    window.Docyt.vent.on('plaid:account:connected', (public_token: string, metadata: IPlaidMetadata) => {
      onAggregatorConnected({
        ...accountData,
        // eslint-disable-next-line camelcase
        plaidPublicToken: public_token,
        plaidAccountId:   metadata.account_id,
      });
    });

    return () => {
      window.Docyt.vent.off('plaid:account:connected');
    };
  }, [accountData, handleAggregatorAccountConnected, onAggregatorConnected]);

  const { mutate: generate } = generateLink;
  const { mutate: generateConnectAggregatorToken } = connectAggregatorToken;
  const handleConnectToAggregator = useCallback((data: IConnectNewFinancialInstitutionData, aggregator?: TAggregatorsType, isQuilttItegrationEnabled?:boolean) => {
    setAccountData(() => ({ ...data, aggregator }));
    switch (aggregator) {
      case 'quiltt':
        openQuiltt();
        break;
      case 'plaid':
      default:
        // If the feature is enabled, the Plaid link token should be generated by the banking service.
        if(isQuilttItegrationEnabled) {
          generateConnectAggregatorToken({
            management_group_id: data.managementGroupId,
            product:'transactions'
          },{
            onSuccess: (response) => {
              // Currently, we are only considering Plaid in this API call, as Quilt is already handled by the frontend. We'll address this in the next phase.
              if(response.plaid){
                loadPliadUI(response.plaid.linkToken)
              }
            }
          })
        } else {
          generate({
            product: 'transactions',
          }, {
            onSuccess: (financialInsitutionConnection) => {
              loadPliadUI(financialInsitutionConnection.linkToken);
            },
          });
        }
        break;
    }
  }, [generate, loadPliadUI, openQuiltt]);

  const { mutate: retrieve } = retrieveLink;
  const { mutate: reconnectToken } = reconnectAggregatorToken;
  const handleReconnectToAggregator = useCallback((data: IConnectNewFinancialInstitutionData, isQuilttItegrationEnabled?:boolean) => {
    setAccountData(data);
    if(isQuilttItegrationEnabled) {
      window.Docyt.vent.trigger('show:spinner');
      reconnectToken({
        financialInstitutionConnectionId:data.id
      }, {
        onSuccess: (financialInsitutionConnection) => {
          window.Docyt.vent.trigger('hide:spinner');
          if(data.aggregator === 'quiltt') {
            importQuilttSession(financialInsitutionConnection.quiltt.session);
            setReConnectInstitution({
               connectionId: financialInsitutionConnection.quiltt.connectionId,
               intitution: financialInsitutionConnection.quiltt.instituionId
            })
          } else {
            loadPliadUI(financialInsitutionConnection.plaid.linkToken);
          }
        }
      })
    } else {
      window.Docyt.vent.trigger('show:spinner');
      retrieve({
        financialInstitutionConnectionId: data.id,
      }, {
        onSuccess: (financialInsitutionConnection) => {
          window.Docyt.vent.trigger('hide:spinner');
          loadPliadUI(financialInsitutionConnection.linkToken);
        },
      });
    }
  }, [retrieve, loadPliadUI, setReConnectInstitution]);

  return {
    connectAggregator:     handleConnectToAggregator,
    reconnectToAggregator: handleReconnectToAggregator,
    generateLinkMutation:  generateLink.isError ? generateLink : connectAggregatorToken,
  };
};

export {
  useConnectAggregatorAccount,
};
