/* eslint-disable eqeqeq */
import React from 'react';

import { IService, TServiceCode } from '@src/types/services';

import ServiceIcon from './service_icon';

const getServiceName = (serviceCode: TServiceCode) => {
  switch (serviceCode) {
    case window.Docyt.Common.Constants.SERVICE_TYPES.AP_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.AP_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.RECEIPT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.RECEIPT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.REPORT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.REPORT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.BANKING_ACCOUNT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.BANKING_ACCOUNT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.REVENUE_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.REVENUE_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.RECONCILIATION_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.RECONCILIATION_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.EXPENSE_REPORT_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.EXPENSE_REPORT_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.AR_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.AR_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.VENDOR_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.VENDOR_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.MAILROOM_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.MAILROOM_SERVICE;
    case window.Docyt.Common.Constants.SERVICE_TYPES.METRICS_SERVICE:
      return window.Docyt.Common.Constants.SERVICE_NAMES.METRICS_SERVICE;
    default:
      return null;
  }
};

interface IServiceMenuProps {
  serviceCode: TServiceCode,
  service: IService,
}

const ServiceMenu = ({
  service,
  serviceCode,
}: IServiceMenuProps) => {
  const noService =
    serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.RECONCILIATION_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.BANKING_ACCOUNT_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.REPORT_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.AR_SERVICE
    && serviceCode != window.Docyt.Common.Constants.SERVICE_TYPES.VENDOR_SERVICE;

  return (
    <div className="service-header btn-group">
      <div className="pull-left">
        <ServiceIcon serviceCode={ serviceCode } />
      </div>
      <div className="business-service-dropdown-menu-wrapper">
        <span className="service-header-title pointer dropdown-toggle" data-toggle="dropdown">
          { getServiceName(serviceCode) }
          {' '}
        </span>
        {
          noService
          && (
            <p className="service-email-panel in-blue-900 font-12">
              <a href={ `mailto:${service.uploadEmail}` }>
                <i className="icon icon-forwarding-email font-16 m-r-5" />
                {' '}
                { service.uploadEmail }
              </a>
            </p>
          )
        }
      </div>
    </div>
  );
};

export default ServiceMenu;
