import React, { useCallback, useMemo } from 'react';

import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useUpdateReportDatas } from '@src/hooks/queries/report_service/report_datas';
import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { endOfWeekApiDate, endOfMonthApiDate, formatApiDate, formatDate, parseApiDate, startOfWeekApiDate, startOfMonthApiDate } from '@src/utils/date_helpers';

import MultiColumnAddField from '@src/components/business_reports/common/multi_column_add_field';
import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import Filter, { useFilterData } from '@src/components/ui_v2/filter';
import { hideZeroRowsType } from '@src/components/ui_v2/filter/atoms';
import { CheckboxInput } from '@src/components/ui_v2/inputs';
import { ReconciliationOutlineIcon } from '@src/components/utils/icomoon';
import { useSection } from '@src/components/utils_v2/section';

import { reportDataPeriodType } from '../../atoms';
import { IReportDatasCollection, TFilterData, reportFilterConfiguration } from '../hooks';

import styles from '../styles.module.scss';

const ReportDataTableFilter = ({
  configurations,
  report,
  collection,
  isShownCustomize,
  setUpdate
}: {
  configurations: IReportConfiguration[];
  report: IReport;
  collection: IReportDatasCollection;
  isShownCustomize: boolean;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const section = useSection();
  const filterData: TFilterData = useFilterData(section);
  const [value, setValue] = useRecoilState(hideZeroRowsType);
  const { mutate } = useUpdateReportDatas();

  const periodType = useRecoilValue(reportDataPeriodType);
  const reportFilters = reportFilterConfiguration(configurations);

  const startDate = useMemo(() => {
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
      return formatApiDate(formatDate(filterData?.reportDataRange?.gte));
    }
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY) {
      return startOfWeekApiDate(parseApiDate(filterData?.reportDataRange?.gte));
    }

    return startOfMonthApiDate(parseApiDate(filterData?.reportDataRange?.gte));
  }, [periodType, filterData]);
  const endDate = useMemo(() => {
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
      return formatApiDate(formatDate(filterData?.reportDataRange?.lte));
    }
    if (periodType === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.WEEKLY) {
      return endOfWeekApiDate(parseApiDate(filterData?.reportDataRange?.lte));
    }

    return endOfMonthApiDate(parseApiDate(filterData?.reportDataRange?.lte));
  }, [periodType, filterData]);
  
  const { minUpdatedAtString, minUpdatedAt } = useMemo(() => {
    let updatedAtString = collection?.records?.[0]?.updatedTimeString;
    let tempUpdatedAt = moment(new Date(3000, 1, 1));

    collection?.records?.forEach((reportData) => {
      if (reportData.updatedAt) {
        const currentUpdatedAt = moment(reportData.updatedAt);

        if (tempUpdatedAt >= currentUpdatedAt) {
          tempUpdatedAt = currentUpdatedAt;
          updatedAtString = reportData.updatedTimeString;
        }
      }
    });

    const updatedAt = tempUpdatedAt.format('ddd, MMM D YYYY, h:mm A');

    return { minUpdatedAtString: updatedAtString, minUpdatedAt: updatedAt };
  }, [collection.records]);

  const handleClick = useCallback(() => {
    if (startDate !== '' && endDate !== '') {
      mutate({
        reportId:        report.id,
        startDate,
        endDate,
        periodType,
        latestUpdatedAt: minUpdatedAt,
      }, {
        onSuccess: () => {
          setUpdate(true);
        },
      });
    }
  }, [
    mutate,
    report.id,
    startDate,
    endDate,
    periodType,
    minUpdatedAt,
    setUpdate
  ]);

  return (
    <Filter.TableContainer className={ styles['table-filter-group'] }>
      <div className={ styles['filter-area'] }>
        <Filter.TextNormalFiled
          className={ styles['search-width'] }
          label="Search by Name"
          placeholder="Search by Name"
        />
        <Filter.DateField
          hideAllTime
          hideClear
          className={ styles['filter-width'] }
          name="reportDataRange"
          periodType={ periodType }
        />

        {isShownCustomize && (
          <MultiColumnAddField
            collection={ collection }
            kind="reportDetail"
            placeholder="Customize"
            report={ report }
            reportFilters={ reportFilters }
          />
        )}

        <CheckboxInput
          checked={ value }
          title="Hide empty rows"
          onChange={ (checked: boolean) => setValue(checked) }
        />
      </div>
      <div className={ styles['update-info-area'] }>
        <Text className={ styles['update-title'] }>
          Last Updated:
        </Text>
        { minUpdatedAtString && (
          <Text className={ styles['update-value-txt'] }>
            { minUpdatedAtString }
          </Text>
        ) }
        <Button
          prefixIcon={ <ReconciliationOutlineIcon color="black" fontSize={ 15 } fontVariant="semi-bold" /> }
          variant="link"
          onClick={ handleClick }
        />
      </div>
    </Filter.TableContainer>
  );
};

export default React.memo(ReportDataTableFilter);
