import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import {
  vendorServiceAuditLogPath,
  vendorServicePaymentPath,
  vendorServiceProfilePath,
  vendorServiceTaxInfoPath,
} from '@src/routes';
import { IBusinessVendor } from '@src/types/business_vendors';
import { backboneNavigateToUrl } from '@src/utils/navigate';
import { camelizeKeys } from '@src/utils/transform_keys';

import Root from '@src/components/root';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { Button } from '@src/components/ui_v2/buttons';

import AuditLog from './audit_log/audit_log';
import Payments from './payments/payments';
import VendorLinkLedgerEditContainer from './profile/edit_link_ledger/link_ledger_edit_container';
import VendorProfileEditContainer from './profile/edit_profile/profile_edit_container';
import Profile from './profile/profile';
import TaxInfo from './tax_info/tax_info';

import styles from './styles.module.scss';

type TTabOption = {
  label: string,
  value: string,
  link: string,
};

const MyVendorsDetailPage = ({ vendor }: { vendor: any }) => {
  const businessId = vendor.changed.business_id;
  const vendorId = vendor.changed.id;
  const [tabsData, setTabsData] = useState<TTabOption[]>([]);
  const [editProfile, setEditProfile] = useState<boolean>(true);
  const [vendorData, setVendorData] = useState<IBusinessVendor | null>(null);
  const [editLinkLedger, setEditLinkLedger] = useState<boolean>(true);

  const editProfileClasses = classNames(styles['edit-vendor-profile-content'], {
    [styles.hidden]: editProfile,
  });

  const editLinkLedgerClasses = classNames(styles['edit-link-ledger'], {
    [styles.hidden]: editLinkLedger,
  });

  useEffect(() => {
    if (vendor) {
      setVendorData(camelizeKeys(vendor.changed) as IBusinessVendor);

      setTabsData([
        {
          label: 'Payments',
          value: 'payments',
          link:  vendorServicePaymentPath(businessId, vendorId),
        },
        {
          label: 'Profile',
          value: 'profile',
          link:  vendorServiceProfilePath(businessId, vendorId),
        },
        {
          label: 'Tax info and Docs',
          value: 'tax-info',
          link:  vendorServiceTaxInfoPath(businessId, vendorId),
        },
        {
          label: 'Audit Log',
          value: 'audit-log',
          link:  vendorServiceAuditLogPath(businessId, vendorId),
        },
      ]);
    }
  }, [vendor, setVendorData, businessId, vendorId]);

  const activeTab: TTabOption | null | undefined = useMemo(() => {
    if (tabsData && tabsData.length > 0) {
      return tabsData.find((it: TTabOption) => window.location.pathname === it.link);
    }

    return null;
  }, [tabsData]);

  return (
    <Root>
      <div className={ styles['vendor-service-container'] }>
        <div className={ styles['vendor-service-content'] }>
          <DetailsPageTitle
            isSmallTitle
            breadcrumbs={ {
              title: 'My Vendors',
              href:  '',
            } }
            className={ styles.breadcrumbs }
            title={ <span className="font-bold">{vendorData?.name}</span> }
          />
          <div className={ styles['vendor-service-tabs'] }>
            {tabsData && tabsData.length > 0 && tabsData.map((tab: TTabOption) => (
              <Button
                key={ tab.value }
                className={ classNames(styles['vendor-service-tab'], {
                  [styles.active]: activeTab?.value === tab.value,
                }) }
                variant="ghost"
                onClick={ () => backboneNavigateToUrl(tab.link) }
              >
                <span title={ tab.label }>{tab.label}</span>
              </Button>
            ))}
          </div>

          {activeTab?.value === 'payments' && <Payments />}
          {activeTab?.value === 'profile'
            && (
              <Profile
                businessId={ businessId }
                editLinkLedger={ editLinkLedger }
                editProfile={ editProfile }
                setEditLinkLedger={ setEditLinkLedger }
                setEditProfile={ setEditProfile }
                setVendorData={ setVendorData }
                vendor={ vendorData as IBusinessVendor }
              />
            )}
          {activeTab?.value === 'tax-info' && <TaxInfo />}
          {activeTab?.value === 'audit-log' && <AuditLog />}
        </div>
        <div className={ editProfileClasses }>
          <VendorProfileEditContainer
            editProfile={ editProfile }
            setEditProfile={ setEditProfile }
            setVendorData={ setVendorData }
            vendor={ vendorData as IBusinessVendor }
          />
        </div>
        <div className={ editLinkLedgerClasses }>
          <VendorLinkLedgerEditContainer
            editLinkLedger={ editLinkLedger }
            setEditLinkLedger={ setEditLinkLedger }
            setVendorData={ setVendorData }
            vendor={ vendorData as IBusinessVendor }
          />
        </div>
      </div>
    </Root>
  );
};

export default MyVendorsDetailPage;
